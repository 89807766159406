.mainTopNavBlur {

    /* content: ''; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.mainTopNavBlur::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    backdrop-filter: blur(5px);
    mask: linear-gradient(to top, transparent, rgb(255, 255, 255) 50%);
    transition: 1s;
}