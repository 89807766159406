@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  box-sizing: border-box;
}
html,
body {
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  width: 100vw;
  @apply dark:bg-primaryy-dark-1000
}

#root {
  height: 100%;
  width: 100%;
}


@layer base {





  .theme-smooth {
    /* transition: border-color  2000ms cubic-bezier(0.4, 0, 0.2, 1); */
    transition: color 2000ms cubic-bezier(0.4, 0, 0.2, 1), background-color 2000ms cubic-bezier(0.4, 0, 0.2, 1), border-color 2000ms cubic-bezier(0.4, 0, 0.2, 1), opacity 2000ms cubic-bezier(0.4, 0, 0.2, 1);
    /* transition: border 2000ms cubic-bezier(0.4, 0, 0.2, 1); */
    /* transition: border-color, 2s cubic-bezier(0.4, 0, 0.2, 1); */

  }

  .theme-smooth-shadow {
    transition: color 2000ms cubic-bezier(0.4, 0, 0.2, 1), background-color 2000ms cubic-bezier(0.4, 0, 0.2, 1), border-color 2000ms cubic-bezier(0.4, 0, 0.2, 1), opacity 2000ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 2000ms cubic-bezier(0.4, 0, 0.2, 1);

  }

  .background-gradient {
    background: linear-gradient(303deg, rgba(2, 0, 36, 0.0055671926973914765) 41%, rgba(171, 47, 214, 0.5994047277113971) 42%, rgba(98, 233, 48, 0.2576680330335259) 47%, rgba(76, 133, 171, 1) 47%, rgba(76, 133, 171, 0.09240192659094892) 73%);
  }

  .customScrollbar::-webkit-scrollbar {
    width: 2px;
    /* Width of the scrollbar */
    height: 2px;
    cursor: pointer;
  }

  .customScrollbar::-moz-scrollbar {
    width: 2px;
    /* Width of the scrollbar */
    height: 2px;
    cursor: pointer;
  }

  .customScrollbar::-webkit-scrollbar-track {
    background-color: none;
    @apply mx-3 md:ml-[250px]
    /* Background color of the scrollbar track */
  }

  .customScrollbar::-moz-scrollbar-track {
    background-color: none;
    /* Background color of the scrollbar track */
  }

  .customScrollbar::-moz-scrollbar-thumb {
    /* Color of the scrollbar thumb */
    border-radius: 5px;
    @apply bg-gray-500/20
    /* Radius of the scrollbar thumb */
  }

  .customScrollbar::-webkit-scrollbar-thumb {
    /* Color of the scrollbar thumb */
    border-radius: 50px;
    @apply bg-gray-500/20
    /* Radius of the scrollbar thumb */
  }



}

.hideNormalInputBehavour input::-webkit-outer-spin-button,
.hideNormalInputBehavour input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hideNormalInputBehavour input[type=number] {
    -moz-appearance: textfield;
}

.hideNormalInputBehavour input[type=password]::-ms-reveal,input[type=password]::-ms-clear{
    display: none;
}


.withLeft .simplebar-track {
  @apply mx-3 md:ml-[250px] transition-all duration-300
}

.noleft .simplebar-track {
  
  @apply mx-3 md:ml-[106px] lg:ms-[126px] transition-all duration-300
}

.fullwidthscroll .simplebar-content-wrapper{
  display: flex;
  flex-direction: column;
}

.fullwidthscroll .simplebar-content{
  flex-grow: 1;
}
.no-scrollbar::-webkit-scrollbar{
  display: none;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 2px; /* Adjust width as desired */
  height: 2px; /* Adjust height for horizontal scrollbar */
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.356); /* Optional: Change thumb color */
  border-radius: 2px; /* Optional: Rounded corners for thumb */
}



